#formresult {
  font-size: 1em;
  margin: 25px 0;
  padding: 15px;
  /*width: 350px;*/
  background-color: #fff;
  color: #000;
}

.displayOK {
  border: 1px solid #999;
}

.displayErr {
  border: 2px solid #f00;
  max-width: 45%;
}

.requiredLabel {
  color: #c00;
  font-weight: bold;
}

.form {
  /*div.error {
  	border: 2px solid #c00;
  	display: inline;
  	vertical-align: top;
  	font-size: 1.1em;
  	padding: 4px;
  	margin: 0 0 0 6px;
  	color: #333;
  }*/
}
.form input,
.form textarea,
.form select {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
  margin: 0 0 2px 0;
  padding: 0.25em 0 0.25em 2px;
  max-width: 45%;
  font-size: inherit;
  line-height: normal;
}
@media only screen and (max-width: 37.4375em) {
  .form input,
.form textarea,
.form select {
    max-width: 100%;
  }
}
.form select {
  padding: 0;
}
.form input[type=submit],
.form input[type=button] {
  padding: 4px 6px;
  font-size: 1.2em;
  font-weight: bold;
  background-color: #eee;
}
.form input[type=radio],
.form input[type=checkbox],
.form input[type=image] {
  border: none;
  background: none;
}
.form .requiredField {
  border-left: 3px solid #c00;
  font-weight: bold;
}
.form input[required],
.form select[required],
.form textarea[required] {
  border-top: 2px solid #ccc;
  border-left: 3px solid #c00;
}
.form label {
  font-weight: bold;
}
.form fieldset {
  margin: 1em 0;
  border: none;
  width: auto;
  padding: 0;
}
.form legend {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0.5em 0;
  line-height: 1.5em;
}
.form input.placeholder,
.form textarea.placeholder {
  color: #888;
  font-style: italic;
}
.form input.placeholder:focus,
.form textarea.placeholder:focus {
  color: inherit;
  font-style: inherit;
}
.form input::-webkit-input-placeholder,
.form textarea::-webkit-input-placeholder {
  color: #888;
  font-style: italic;
  /*&:focus {
  	color: inherit;
  	font-style: inherit;
  }*/
}
.form input::-moz-placeholder,
.form textarea::-moz-placeholder {
  color: #888;
  font-style: italic;
  /*&:focus {
  	color: inherit;
  	font-style: inherit;
  }*/
}
.form input:-moz-placeholder,
.form textarea:-moz-placeholder {
  color: #888;
  font-style: italic;
  /*&:focus {
  	color: inherit;
  	font-style: inherit;
  }*/
}
.form input:-ms-input-placeholder,
.form textarea:-ms-input-placeholder {
  color: #888;
  font-style: italic;
  /*&:focus {
  	color: inherit;
  	font-style: inherit;
  }*/
}