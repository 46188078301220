@import "bourbon/bourbon";
@import "breakpoints"; // global vars

#formresult {
	font-size: 1em;
	margin: 25px 0;
	padding: 15px;
	/*width: 350px;*/
	background-color: #fff;
	color: #000;
}
.displayOK { border: 1px solid #999; }
.displayErr { border: 2px solid #f00;max-width: 45%; }
.requiredLabel { color: #c00; font-weight: bold; }

.form {
	input,
	textarea,
	select {
		border: 1px solid #ccc;
		background-color: #fff;
		color: #000;
		margin: 0 0 2px 0;
		padding: 0.25em 0 0.25em 2px;
		max-width: 45%;
		font-size: inherit;
		line-height: normal;
		@media only screen and (max-width: $breakpoint-xs-max) {
			max-width: 100%;
		}
	}
	select {
		padding: 0;
	}
	input[type=submit],
	input[type=button] {
		padding: 4px 6px;
		font-size: 1.2em;
		font-weight: bold;
		background-color: #eee;
	}
	input[type=radio],
	input[type=checkbox],
	input[type=image] {
		border: none;
		background: none;
	}
	.requiredField { border-left: 3px solid #c00; font-weight: bold; }
	input[required],
	select[required],
	textarea[required] {
		border-top: 2px solid #ccc;
		border-left: 3px solid #c00;
	}
	/*div.error {
		border: 2px solid #c00;
		display: inline;
		vertical-align: top;
		font-size: 1.1em;
		padding: 4px;
		margin: 0 0 0 6px;
		color: #333;
	}*/
	label { font-weight: bold; }
	fieldset {
		margin: 1em 0;
		border: none;
		width: auto;
		padding: 0;
	}
	legend {
		font-weight: bold;
		font-size: 1.5em;
		margin: 0.5em 0;
		line-height: 1.5em;
	}

	input,
	textarea {
		&.placeholder {
			color: #888;
			font-style: italic;
			&:focus {
				color: inherit;
				font-style: inherit;
			}
		}
		@include placeholder {
			color: #888;
			font-style: italic;
			/*&:focus {
				color: inherit;
				font-style: inherit;
			}*/
		}
	}
}
